<template>
    <b-row>
        <b-col md="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
                    <b-overlay :show="unitLoad">
                        <div class="application-form-wrapper">
                            <div class="application-itmes">
                                <b-row>
                                    <b-col sm="12">
                                        <div class="group-form-card">
                                            <b-card>
                                                <div>
                                                    Dear Sir,<br>

                                                    We have reserved a booth in the Bangladesh pavilion through the Export Promotion Bureau (EPB) for
                                                    the upcoming edition of {{ fair_info.fair_name }}, tscheduled to take place from {{ fair_info.date_from }} to {{ fair_info.date_from}} in {{ fair_info.city }}, {{ getCountryName(fair_info.country_id) }}.
                                                    We kindly request your assistance in providing us with a visa invitation letter, in compliance with the
                                                    following terms and conditions: <br>
                                                    <p class="ml-5">
                                                    1. We acknowledge and agree that the Exspanort Promotion Bureau (EPB) facilitates exhibitors by
                                                    issuing visa invitation letters solely to those who have received approval from the competent
                                                    authority of the Export Promotion Bureau (EPB).
                                                    </p>
                                                    <br>
                                                    <p class="ml-5">
                                                        2. We understand and agree that the decision to grant or reject a visa rests solely with the Canadian
                                                        High Commission. Neither EPB, the show management/authority, nor their authorized agent in
                                                        Bangladesh has any control over this process.
                                                    </p><br>
                                                    <p class="ml-5">
                                                        3. We understand and agree that the EPB/fair authority or its authorized agent in Bangladesh does
                                                        not have the authority to schedule appointments, submit passports, or receive decisions from the
                                                        VFS or the relevant High Commission/Embassy.
                                                    </p>
                                                        <br>
                                                    <p class="ml-5">
                                                        4. We understand and agree that visa processing times may be longer than usual. Neither EPB nor
                                                        the concerned show management or its authorized agent in Bangladesh have any control over the
                                                        processing time.
                                                    </p><br>
                                                    <p class="ml-5">
                                                        5. We understand and agree that {{ fair_info.fair_name }} is an international exhibition, and in the event of
                                                        visa delays affecting international exhibitors worldwide, the show management may consider
                                                        revising the exhibition dates to a more suitable time. Should this occur, we commit to participating
                                                        accordingly. We acknowledge that no compensation or refund will be provided to us if the
                                                        exhibition dates are changed.
                                                    </p><br>
                                                    We kindly request the visa invitation letter to be issued at your earliest convenience, as we need to initiate
                                                    the visa application process promptly. <br>
                                                    Thank you for your attention to this matter. We look forward to your favorable response.
                                                </div>
                                                <br>
                                               <b-row>
                                                    <b-col sm="6">
                                                            <ValidationProvider name="Consent Person Name" vid="name" rules="required" v-slot="{ errors }">
                                                                <b-form-group label-for="name">
                                                                    <template v-slot:label>
                                                                        Consent Person Name<span class="text-danger">*</span>
                                                                    </template>
                                                                    <b-form-input v-model="consent.consent_per_name" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider name=" Consent Person Designation" vid="designation" rules="required" v-slot="{ errors }">
                                                                <b-form-group label-for="designation">
                                                                    <template v-slot:label>
                                                                        Consent Person Designation <span class="text-danger">*</span>
                                                                    </template>
                                                                    <b-form-input v-model="consent.consent_per_designation" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Consent Person Signature" vid="signature"
                                                                :rules="`${(consent?.consent_per_signature) ? '' : 'required'}`" v-slot="{ errors }">
                                                                <b-form-group label-for="signature">
                                                                    <template v-slot:label>
                                                                        Consent Person Signature <span v-if="!consent?.consent_per_signature"
                                                                            class="text-danger">*</span>
                                                                        <span v-if="consent?.consent_per_signature">
                                                                            <a target="_blank" :href="baseUrl + 'storage/' + consent?.consent_per_signature"
                                                                                title="Download Attachment" class="ml-2">{{ $t('globalTrans.download')}} <i
                                                                                    class="ri-download-cloud-fill"></i></a>
                                                                        </span>
                                                                    </template>
                                                                    <b-form-file plain accept=".jpeg,.jpg,.png" v-model="consent.file"
                                                                        v-on:change="onFileChange($event)" :state="errors[0] ? false : (valid ? true : null)"></b-form-file>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    <div class="invalid-feedback d-block text-muted">
                                                                        [Maximum file size is 2 MB and file format is JPG/JPEG/PDF]
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                               </b-row>
                                            </b-card>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
        </b-col>
    </b-row>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { fairPartStoreConsentApi } from '../../../api/routes'

export default {
    props: ['app_id', 'consent', 'app_status', 'baseUrl', 'fair_info'],
    data () {
        return {
            errors: [],
            valid: null,
            unitLoad: false,
            consent_form: {}
        }
    },
    created () {
        if (this.$route.query.id) {
            this.consent = JSON.parse(JSON.stringify(this.consent))
            // this.attach = JSON.parse(JSON.stringify(this.attachments))
        }
    },
    watch: {
        currentLocale: function (newVal, oldVal) {
            if (newVal !== oldVal) {
            }
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        onFileChange (event) {
            const input = event.target
            if (input.files && input.files[0]) {
                const reader = new FileReader()
                reader.onload = (e) => {
                this.consent.signature = e.target.result
                }
                reader.readAsDataURL(input.files[0])
            } else {
                this.consent.signature = ''
            }
        },
        getCountryName (id) {
        const obj = this.$store.state.CommonService.commonObj.countryList.find(item => item.value === parseInt(id))
        return obj?.text_en
        },
        async submit () {
            var check = await this.$refs.form.validate()
            if (check) {
                this.unitLoad = true
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const loadingState = { loading: false, listReload: false }
                this.consent.app_id = this.app_id
                this.consent.app_status = this.app_status
                if (this.$route.query.id) {
                    this.consent.edit_mode = 1
                } else {
                    this.consent.edit_mode = 0
                }

                const result = await RestApi.postData(internationalTradeFairServiceBaseUrl, fairPartStoreConsentApi, this.consent)
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                this.unitLoad = false
                if (result.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: this.app_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    return result
                } else {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: this.$t('globalTrans.form_error_msg'),
                        color: '#ee5253'
                    })
                    this.$refs.form.setErrors(result.errors)
                }
            }
        }
    }
}
</script>
